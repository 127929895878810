import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import "../assets/style/globalStyle.scss";
import arrowleft from "../assets/img/arrow_left.png";
import arrowright from "../assets/img/arrow_right.png";
import googleplay from "../assets/img/GooglePlay.png";
import GooglePlay from "../assets/img/google play.png";
import appstore from "../assets/img/AppStore.png";
import background from "../assets/img/bossPlayground.png";
import m6 from "../assets/partners/banniereM6.png";
import stats from "../assets/img/stats.png";
import phonescreen from "../assets/img/PhoneScreen.png";
import trophy from "../assets/img/trophy.png";
import terrain from "../assets/img/terrain.png";
import basketball from "../assets/img/basket-ball.png";
import calendar from "../assets/img/calendar.png";
import coachingImage from "../assets/img/coachingImage.png";
import gabdou from "../assets/img/gabdou.png";
import anoureth from "../assets/img/anoureth.jpeg";
import fahim from "../assets/img/fahim.png";
import chloe from "../assets/img/chloe.jpg";
import tiago from "../assets/img/tiago.jpg";
import tristan from "../assets/img/tristan.jpg";
import ballnstreet1 from "../assets/img/BallNStreet-1.png";
import ballnstreet2 from "../assets/img/BallNStreet-2.png";
import pumaAdsInterstitiel from "../assets/ads/puma_320x480.jpg";
import fullscreeninterstitial from "../assets/img/Full-Screen_Interstitial.png";
import bdpLille from "../assets/ads/bdplille.png";
import Vertical_rectangle from "../assets/img/Vertical_rectangle.png";
import NavBar from "../components/navBarSite";
import Footer from "../components/footer";
import TeamCard from "../components/teamCard";
import EventCard from "../components/eventCard";
import OpinionCard from "../components/opinionCard";
import ArticleCard from "../components/articleCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import YouTube from "react-youtube";
import { graphql } from "gatsby";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { countAllPlaygrounds } from "../services/playgroundService";
import { countAllEvents } from "../services/eventService";
import { countAllUsers } from "../services/authService";
import { countAllGames } from "../services/gameService";
import { getAllArticles } from "../services/articleService";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

//Ads

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export default function Home({ data }) {
  const [noRedirect, setNoRedirect] = useState(false);
  const path = new URLSearchParams(useLocation().search).get("p");
  const query = new URLSearchParams(useLocation().search).get("q");
  const [countPlaygrounds, setCountPlaygrounds] = useState([]);
  const [countEvents, setCountEvents] = useState([]);
  const [countUsers, setCountUsers] = useState([]);
  const [countGames, setCountGames] = useState([]);
  const [articles, setArticles] = useState([]);
  const [modalAds, setModalAds] = useState(true);
  const { posts } = data.blog;

  const isPhone = useMedia("(max-width: 767px)");
  const isTablet = useMedia("(min-width: 768px) and (max-width: 1224px)");
  const isLaptop = useMedia("(min-width: 1225px) and (max-width: 1500px)");

  const getAllArticlesToModerate = async () => {
    const data = await getAllArticles();

    if (!data) {
      return (
        <div className="container is-fullhd">
          <h1>Pas d'articles en cours</h1>
        </div>
      );
    }

    setArticles(data);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    laptop: {
      breakpoint: { max: 1700, min: 1275 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1275, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsiveTeam = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    laptop: {
      breakpoint: { max: 1500, min: 1100 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1100, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const countAllValidatedPlaygrounds = async () => {
    const data = await countAllPlaygrounds();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountPlaygrounds(data);
  };

  const countAllUsersApp = async () => {
    const data = await countAllUsers();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountUsers(data);
  };

  const countAllValidatedEvents = async () => {
    const data = await countAllEvents();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'évènements en cours</h1>
        </div>
      );
    }

    setCountEvents(data);
  };

  const countallGames = async () => {
    const data = await countAllGames();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>OOOOOOOOHHHHHHHH DAMN</h1>
        </div>
      );
    }

    setCountGames(data);
  };

  useEffect(() => {
    if (path) {
      if (query) navigate(`/app/redirect?p=${path}&q=${query}`);
      else navigate(`/app/redirect?p=${path}`);
    } else {
      setNoRedirect(true);
    }
  }, []);

  useEffect(() => {
    countAllValidatedPlaygrounds();
    countAllValidatedEvents();
    countAllUsersApp();
    countallGames();
    getAllArticlesToModerate();
  }, []);

  const styles = {
    container: {
      position: "relative",
      margin: 0,
      padding: 0,
      fontFamily: "Montserrat",
      width: "100%",
      overflow: modalAds ? "initial" : "hidden",
    },
    background: {
      display: "flex",
      flexDirection: "column",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.5),
      rgba(161,84,119, 0.5)),url(${background})`,
      height: isPhone ? 500 : "100vh",
      backgroundPosition: "center",
      marginTop: 73,
      padding: isPhone ? "45px 0px 0px 27px" : "200px 0px 0px 104px",
    },
    backgroundM6: {
      display: "flex",
      flexDirection: "column",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${m6})`,
      height: "75vw",
      width: "100vw",
      backgroundPosition: "center",
      // marginTop: 73,
      marginTop: isPhone ? -50 : isTablet ? -100 : -225,
      marginBottom: isPhone ? -125 : isTablet ? -100 : -225,
    },
    statsSection: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "row",
      textAlign: "center",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.8),
      rgba(161,84,119, 0.8)),url(${stats})`,
      height: "35vh",
      backgroundPosition: "fixed",
      padding: 30,
      marginBottom: 70,
      // marginTop: 70,
    },
    statsSectionMobile: {
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "column",
      textAlign: "center",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.8),
      rgba(161,84,119, 0.8)),url(${stats})`,
      height: "35vh",
      backgroundPosition: "center",
      marginBottom: 70,
      marginTop: 70,
    },
    columnSections: {
      display: "flex",
      flexDirection: "column",
    },
    circle: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 950,
      height: 950,
      marginTop: 111,
      marginLeft: -250,
    },
    circleLaptop: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 700,
      height: 700,
      marginTop: 111,
      marginLeft: -250,
    },
    circleMobile: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 550,
      height: 550,
      marginTop: 290,
      marginLeft: -150,
    },
    circleTablet: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 700,
      height: 700,
      marginTop: 300,
      marginLeft: "auto",
      marginRight: "auto",
    },
    circle2: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 680,
      height: 680,
      zIndex: -1,
      marginTop: -60,
      marginLeft: 1550,
    },
    circle2Mobile: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 550,
      height: 550,
      marginTop: 230,
      marginLeft: 200,
    },
    circle3: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 800,
      height: 800,
      marginLeft: -150,
      marginBottom: 20,
    },
    circle3Mobile: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 450,
      height: 450,
      marginTop: 140,
      marginLeft: -280,
    },
    circle3Tablet: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 550,
      height: 550,
      marginTop: 85,
      marginLeft: "auto",
      marginRight: "auto",
    },
    circle3Laptop: {
      backgroundImage: `linear-gradient(180deg, #D85E4B, #A15477)`,
      borderRadius: "100%",
      width: 650,
      height: 650,
      marginTop: 50,
      marginLeft: -300,
    },
    appSection: {
      display: "flex",
      flexDirection: isPhone ? "column" : "row",
      padding: isPhone ? "0px 18px 0px 18px" : "0px 60px 0px 95px",
      justifyContent: "space-around",
      marginTop: isPhone ? 35 : 140,
    },
    appFonctionalities: {
      display: "flex",
      flexDirection: "row",
    },
    appCircles: {
      minHeight: 56,
      minWidth: 56,
      maxWidth: 56,
      maxHeight: 56,
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    appTitles: {
      color: "black",
      fontSize: "16px",
      fontWeight: 800,
      marginLeft: 12,
    },
    appContent: {
      color: "#6D6D6D",
      marginTop: 7,
      marginLeft: 12,
      width: isPhone ? 300 : 240,
      marginBottom: isPhone ? 30 : 55,
      marginRight: isPhone ? 0 : 45,
    },
    statInfo: {
      fontSize: isPhone ? "48px" : "64px",
      color: "white",
      fontWeight: "bold",
    },
    statTitle: {
      fontSize: isPhone ? "15px" : "24px",
      color: "white",
      marginTop: "10px",
      fontWeight: 400,
    },
    defaultDot: {
      backgroundColor: "#D35D50",
      width: 38,
      height: 8,
      borderRadius: "4px",
      opacity: "0.28",
      marginLeft: 5,
      marginRight: 5,
      border: 0,
    },
    activeDot: {
      backgroundColor: "#D35D50",
      width: 54,
      height: 8,
      borderRadius: "4px",
      marginLeft: 5,
      marginRight: 5,
      border: 0,
    },
    rightArrow: {
      width: 47,
      height: 47,
      backgroundColor: "white",
      padding: 15,
      boxShadow: "0px 1px 21px 6px rgba(0, 0, 0, 0.08)",
    },
    sectionTitles: {
      fontWeight: 600,
      fontSize: "18px",
      color: "#F56D44",
    },
    sectionSubTitle: {
      fontWeight: 800,
      fontSize: "48px",
      color: "black",
    },
    ytbBouton: {
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      backgroundImage: `linear-gradient(90deg, #D85E4B, #A15477)`,
      borderRadius: 25,
      alignItems: "center",
      textAlign: "center",
      paddingTop: 14,
      paddingBottom: 14,
      marginTop: 30,
      marginBottom: isPhone ? 37 : 150,
      width: isPhone ? "100%" : 279,
      alignSelf: "center",
    },
    investBouton: {
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      backgroundImage: `linear-gradient(90deg, #D85E4B, #A15477)`,
      borderRadius: 25,
      alignItems: "center",
      textAlign: "center",
      paddingTop: 14,
      paddingBottom: 14,
      marginTop: isPhone ? 55 : isTablet ? -40 : -100,
      marginBottom: isPhone ? 0 : isTablet ? 90 : 150,
      width: isPhone ? "92%" : 279,
      alignSelf: "center",
    },
    btnArrows: {
      width: "47px",
      height: "47px",
      backgroundColor: "white",
      boxShadow: "0px 1px 19px -5px rgba(0, 0, 0, 0.1)",
      marginLeft: 10,
      padding: 14,
    },
    btnGroup: {
      overflow: "hidden",
      marginLeft: 1655,
      marginTop: -540,
      display: "flex",
      flexDirection: "row",
      zIndex: 1,
    },
    bnCoachingNb: {
      fontSize: "36px",
      fontWeight: 800,
      color: "#F56D44",
    },
    bnCoachingInfo: {
      fontSize: "14px",
      fontWeight: 400,
      color: "black",
    },
  };

  const CustomDot = ({ index, onClick, active }) => {
    return (
      <a
        onClick={() => onClick()}
        style={active ? styles.activeDot : styles.defaultDot}
      >
        {React.Children.toArray()[index]}
      </a>
    );
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div style={styles.btnGroup}>
        <a style={styles.btnArrows} onClick={() => previous()}>
          <img
            src={arrowleft}
            alt="arrow"
            style={{ width: "16px", alignSelf: "center" }}
          ></img>
        </a>
        <a style={styles.btnArrows} onClick={() => next()}>
          <img
            src={arrowright}
            alt="arrow"
            style={{ width: "16px", alignSelf: "center" }}
          ></img>
        </a>
      </div>
    );
  };

  const opts = {
    height: isPhone ? "209" : "500",
    width: isPhone ? "100%" : "750",
    marginRight: 20,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const opts2 = {
    height: "400",
    width: "450",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div style={styles.container}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <meta
        name="google-site-verification"
        content="7wm_f_FocLdbD0qZtqNp-cFPaaJjr_2MD7yxeJC1DLY"
      />
      <NavBar></NavBar>
      {/* BACKGROUND */}
      <div style={styles.background}>
        <h1
          style={{
            fontWeight: "800",
            fontSize: isPhone ? "42px" : "70px",
            color: "white",
          }}
        >
          Ball'N Connect
        </h1>
        <p
          style={{
            fontWeight: "700",
            fontSize: isPhone ? "28px" : "64px",
            color: "white",
            marginBottom: "10px",
          }}
        >
          {isPhone ? (
            <> Le plus grand club de basketball digital au monde</>
          ) : (
            <>
              Le plus grand club de <br /> basketball digital au monde
            </>
          )}
        </p>
        <p
          style={{
            fontWeight: "700",
            fontSize: isPhone ? "14px" : "32px",
            color: "white",
            marginBottom: "10px",
          }}
        >
          FIND PLAY PROGRESS
        </p>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.ballnconnect.ballnconnect&hl=fr&gl=US">
            <img
              src={GooglePlay}
              alt="googleplay"
              style={{
                width: isPhone ? 145 : 236,
                marginTop: 20,
                marginRight: 20,
              }}
            ></img>
          </a>
          <a href="https://apps.apple.com/fr/app/balln-connect/id1534174248">
            <img
              src={appstore}
              alt="appstore"
              style={{ width: isPhone ? 145 : 236 }}
            ></img>
          </a>
        </div>
      </div>

      {/* SECTION INVESTISSEMENT */}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div style={styles.backgroundM6}></div>
        <a
          href="https://www.crowdcube.eu/early-access/ballnconnect?country=FR"
          style={styles.investBouton}
        >
          JE VEUX INVESTIR
        </a>
      </div>

      {/* APP STATS */}
      <div style={!isPhone ? styles.statsSection : styles.statsSectionMobile}>
        {isPhone ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ width: 150 }}>
              <p style={styles.statInfo}>
                {countUsers.countAllUsers !== undefined ||
                countUsers.countAllUsers > 10000
                  ? countUsers.countAllUsers.toString().substring(0, 2) + " K"
                  : 0}
                {/* 28 K */}
              </p>
              <p style={styles.statTitle}>Utilisateurs</p>
            </div>
            <div style={{ width: 150 }}>
              <p style={styles.statInfo}>
                {/* {countPlaygrounds.countPlaygrounds === undefined
                  ? 0
                  : countPlaygrounds.countPlaygrounds} */}
                {/* 1171 */}
                4112
              </p>
              <p style={styles.statTitle}>Terrains</p>
            </div>
          </div>
        ) : (
          <>
            <div>
              <p style={styles.statInfo}>
                {countUsers.countAllUsers !== undefined ||
                countUsers.countAllUsers > 10000
                  ? countUsers.countAllUsers.toString().substring(0, 2) + " K"
                  : 0}
                {/* 28 K */}
              </p>
              <p style={styles.statTitle}>Utilisateurs</p>
            </div>
            <div>
              <p style={styles.statInfo}>
                {/* {countPlaygrounds.countPlaygrounds === undefined
                  ? 0
                  : countPlaygrounds.countPlaygrounds} */}
                4112
                {/* 1171 */}
              </p>
              <p style={styles.statTitle}>Terrains</p>
            </div>
          </>
        )}
        {isPhone ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ width: 150 }}>
              <p style={styles.statInfo}>
                {countEvents.countEvents === undefined
                  ? 0
                  : countEvents.countEvents}
                {/* 45 */}
              </p>
              <p style={styles.statTitle}>Évènements organisés</p>
            </div>
            <div style={{ width: 150 }}>
              <p style={styles.statInfo}>
                {/* {countGames.countGames === undefined
                  ? 0
                  : countGames.countGames} */}
                3823
                {/* 1000 */}
              </p>
              <p style={styles.statTitle}>Matchs créés</p>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div>
              <p style={styles.statInfo}>
                {countEvents.countEvents === undefined
                  ? 0
                  : countEvents.countEvents}
                {/* 45 */}
              </p>
              <p style={styles.statTitle}>Évènements organisés</p>
            </div>
            <div>
              <p style={styles.statInfo}>
                {/* {countGames.countGames === undefined
                  ? 0
                  : countGames.countGames} */}
                3823
                {/* 1000 */}
              </p>
              <p style={styles.statTitle}>Matchs créés</p>
            </div>
          </>
        )}
      </div>
      {/* PRESENTATION APP */}
      {isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleLaptop}></div>
        </div>
      )}
      {isTablet && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleTablet}></div>
        </div>
      )}
      {isPhone && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circleMobile}></div>
        </div>
      )}
      {!isPhone && !isTablet && !isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle}></div>
        </div>
      )}
      <div style={styles.appSection}>
        {isPhone && (
          <>
            <p style={styles.sectionTitles}>La solution</p>
            <p style={styles.sectionSubTitle}>L’application</p>
            <p style={{ color: "black", marginBottom: 50 }}>
              Ball'N Connect est une application mobile qui a pour but
              d'améliorer la pratique du basketball, pour les 14,5 millions de
              fans en France.
            </p>
          </>
        )}
        {!isTablet && (
          <div style={{ ...styles.columnSections, overflow: "hidden" }}>
            <img
              src={phonescreen}
              style={{
                alignSelf: "center",
                width: isPhone ? 400 : "",
                marginLeft: isPhone ? 80 : "",
              }}
              alt="logo"
            />
          </div>
        )}
        <div
          style={{ ...styles.columnSections, width: isPhone ? "100%" : 700 }}
        >
          {!isPhone && (
            <>
              <p style={styles.sectionTitles}>La solution</p>
              <p style={styles.sectionSubTitle}>L’application</p>
              <p style={{ color: "black", marginBottom: 110 }}>
                Ball'N Connect est une application mobile qui a pour but
                d'améliorer la pratique du basketball, pour les 14,5 millions de
                fans en France.
              </p>
            </>
          )}
          {isTablet && (
            <>
              {" "}
              <div style={styles.columnSections}>
                <img
                  src={phonescreen}
                  style={{
                    alignSelf: "center",
                    width: 400,
                    marginLeft: 80,
                    marginTop: -70,
                  }}
                  alt="logo"
                />
              </div>
            </>
          )}
          {isPhone ? (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#AE629B" }}>
                  <img
                    src={terrain}
                    style={{ width: 35, height: 35 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Terrains</p>
                  <p style={styles.appContent}>
                    Trouver des joueurs et des terrains grâce la
                    géolocalisation.
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#FFA02C" }}>
                  <img
                    src={trophy}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Tournois</p>
                  <p style={styles.appContent}>
                    Créer et/ou ajoute ton tournoi pour réunir ta communauté.
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#F8997C" }}>
                  <img
                    src={basketball}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Matchs</p>
                  <p style={styles.appContent}>
                    Créer tes matchs pour affronter d’autres joueurs.
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.appCircles, background: "#F88B9B" }}>
                  <img
                    src={calendar}
                    style={{ width: 28, height: 29 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Camps</p>
                  <p style={styles.appContent}>Progresse via des camps.</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={styles.appFonctionalities}>
                <div style={{ ...styles.appCircles, background: "#AE629B" }}>
                  <img
                    src={terrain}
                    style={{ width: 35, height: 35 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Terrains</p>
                  <p style={styles.appContent}>
                    Trouver des joueurs et des terrains grâce la
                    géolocalisation.
                  </p>
                </div>
                <div style={{ ...styles.appCircles, background: "#FFA02C" }}>
                  <img
                    src={trophy}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Tournois</p>
                  <p style={styles.appContent}>
                    Créer et/ou ajoute ton tournoi pour réunir ta communauté.
                  </p>
                </div>
              </div>
              <div style={styles.appFonctionalities}>
                <div style={{ ...styles.appCircles, background: "#F8997C" }}>
                  <img
                    src={basketball}
                    style={{ width: 40, height: 40 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Matchs</p>
                  <p style={styles.appContent}>
                    Créer tes matchs pour affronter d’autres joueurs.
                  </p>
                </div>
                <div style={{ ...styles.appCircles, background: "#F88B9B" }}>
                  <img
                    src={calendar}
                    style={{ width: 28, height: 29 }}
                    alt="googleplay"
                  ></img>
                </div>
                <div>
                  <p style={styles.appTitles}>Camps</p>
                  <p style={styles.appContent}>Progresse via des camps.</p>
                </div>
              </div>
            </>
          )}
          <div
            style={{
              alignSelf: isPhone ? "center" : "",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 20,
              marginBottom: isPhone ? 50 : 0,
            }}
          >
            <a href="https://play.google.com/store/apps/details?id=com.ballnconnect.ballnconnect&hl=fr&gl=US">
              <img
                src={GooglePlay}
                alt="googleplay"
                style={{ width: isPhone ? 145 : 236, marginRight: 38 }}
              ></img>
            </a>
            <a href="https://apps.apple.com/fr/app/balln-connect/id1534174248">
              <img
                src={appstore}
                alt="appstore"
                style={{ width: isPhone ? 145 : 236 }}
              ></img>
            </a>
          </div>
        </div>
      </div>
      {/* SECTION BALL N STREET
      {isTablet ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: 18,
            paddingRight: 18,
          }}
          id="bnStreet"
        >
          <p style={styles.sectionTitles}>Programme</p>
          <p style={styles.sectionSubTitle}>Ball'N Street</p>
          <p
            style={{
              width: 800,
              marginTop: 35,
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Ball'N Street présente le Last Round 2, l'évènement Streetball le
            plus hype de France évolue &#128293;. Pour se transformer en un
            combo Rap, breakdance et basketball sur 2 jours &#129327; et
            évidemment pleins d'autres surprises !!
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Zoom>
              <img
                src={ballnstreet1}
                style={{
                  alignSelf: "center",
                  width: isPhone ? 400 : 550,
                  marginBottom: 20,
                }}
                alt="logo"
              />
            </Zoom>
            <Zoom>
              <img
                src={ballnstreet2}
                style={{
                  alignSelf: "center",
                  width: isPhone ? 400 : 550,
                  marginBottom: 20,
                }}
                alt="logo"
              />
            </Zoom>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isPhone ? "flex-start" : "center",
            paddingLeft: isPhone ? 18 : 0,
            paddingRight: isPhone ? 18 : 0,
          }}
        >
          <p style={styles.sectionTitles}>Programme</p>
          <p style={styles.sectionSubTitle}>Ball'N Street</p>
          <p
            style={{
              width: isPhone ? "" : 800,
              marginTop: 35,
              textAlign: isPhone ? "start" : "center",
              marginBottom: 20,
            }}
            id="bnStreet"
          >
            Ball'N Street présente le Last Round 2, l'évènement Streetball le
            plus hype de France évolue &#128293;. Pour se transformer en un
            combo Rap, breakdance et basketball sur 2 jours &#129327; et
            évidemment pleins d'autres surprises !!
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: isPhone ? "column" : "row",
              marginBottom: isPhone ? 0 : 20,
            }}
          >
            <Zoom>
              <img
                src={ballnstreet1}
                style={{
                  alignSelf: "center",
                  width: isPhone ? 400 : 550,
                  marginBottom: isPhone ? 20 : 0,
                  marginRight: isPhone ? 0 : 20,
                }}
                alt="logo"
              />
            </Zoom>
            <Zoom>
              <img
                src={ballnstreet2}
                style={{
                  alignSelf: "center",
                  width: isPhone ? 400 : 550,
                  marginBottom: isPhone ? 20 : 0,
                }}
                alt="logo"
              />
            </Zoom>
          </div>
        </div>
      )} */}
      {/* SECTION EVENEMENTS */}
      {isTablet ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: 18,
            paddingRight: 18,
          }}
        >
          <p style={styles.sectionTitles}>Évènements</p>
          <p style={styles.sectionSubTitle}>Calendrier d'évènements</p>
          <p style={{ width: 800, marginTop: 35, textAlign: "center" }}>
            Ball'N Connect organise également de nombreux tournois tels que les
            Boss du Playground ou même le Last Round. Retrouvez ici le
            calendrier de nos prochains événements ainsi qu'une vidéo de
            présentation d'un de nos fameux évènements le Ball'N Street.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 52,
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <YouTube videoId="wtyrlFVfpC8" opts={isLaptop ? opts2 : opts} />
          </div>
          <a href="/eventPage" style={styles.ytbBouton}>
            VOIR NOS ÉVÈNEMENTS
          </a>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isPhone ? "flex-start" : "center",
            paddingLeft: isPhone ? 18 : 0,
            paddingRight: isPhone ? 18 : 0,
          }}
        >
          <p style={styles.sectionTitles}>Évènements</p>
          <p style={styles.sectionSubTitle}>Calendrier d'évènements</p>
          <p
            style={{
              width: isPhone ? "" : 800,
              marginTop: 35,
              textAlign: isPhone ? "start" : "center",
            }}
          >
            Ball'N Connect organise également de nombreux tournois tels que les
            Boss du Playground ou même le Last Round. Retrouvez ici le
            calendrier de nos prochains événements ainsi qu'une vidéo de
            présentation d'un de nos fameux évènements le Ball'N Street.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: isPhone ? "column" : "row",
              width: "100%",
              marginTop: 52,
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <YouTube videoId="wtyrlFVfpC8" opts={isLaptop ? opts2 : opts} />
            {isPhone && (
              <a href="/eventPage" style={styles.ytbBouton}>
                VOIR NOS ÉVÈNEMENTS
              </a>
            )}
          </div>
          {!isPhone && (
            <a href="/eventPage" style={styles.ytbBouton}>
              VOIR NOS ÉVÈNEMENTS
            </a>
          )}
        </div>
      )}
      {/* SECTION TEMOIGNAGES */}
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      >
        <div style={!isPhone ? styles.circle2 : styles.circle2Mobile}></div>
      </div>
      <div
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          display: "flex",
          flexDirection: "column",
          marginBottom: 650,
          marginTop: isPhone ? 100 : "",
        }}
      >
        <div>
          <p style={styles.sectionTitles}>LA MEILLEURE COMMUNAUTÉ</p>
          <p style={styles.sectionSubTitle}>
            Ce qu'en disent nos utilisateurs ⭐
          </p>
        </div>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
          customDot={<CustomDot />}
        >
          <OpinionCard
            title={"L'appli qu'il fallait pour les basketteurs"}
            name={"kainepp"}
            content={
              "Vraiment un réel besoin car cette app permet de localiser les terrains extérieurs et organiser des matchs. Plus jamais se retrouver seul sur un terrain."
            }
            img={"/assets/img/gabdou.png"}
          ></OpinionCard>
          <OpinionCard
            title={"INCROYABLE !"}
            name={"GRG_"}
            content={
              "Depuis que j'ai cette application j'ai beaucoup moins de difficulté à trouver des bons terrains, je la recommande à tout le monde !"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
          <OpinionCard
            title={"Très bonne application"}
            name={"Namir91"}
            content={
              "Application très sociable qui permet de lier la communautée basketball"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
          <OpinionCard
            title={"L'application que j'attendais !"}
            name={"vwvwtz"}
            content={
              "Enfin une application qui te permet de trouver des terrains avec des joueurs, en plus l'option événement est top ! Je valide"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
          <OpinionCard
            title={"Très utile"}
            name={"xs_plm"}
            content={
              "C'est une très bonne application qui est bien organisée, elle te permet de trouver tout les terrains de basket à proximité de chez toi et de participer à des tournois si tu joues généralement tout seul."
            }
            img={"/assets/img/anoureth.jpeg"}
          ></OpinionCard>
          <OpinionCard
            title={"Super"}
            name={"Mezcla92"}
            content={
              "L'application est vraiment top et très utile pour la communautée basketball, une grande avancée en France dans ce domaine merci !"
            }
            img={"/assets/img/fahim.png"}
          ></OpinionCard>
        </Carousel>
      </div>
      {/* PRESENTATION BALLN COACHING */}
      {isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle3Laptop}></div>
        </div>
      )}
      {isTablet && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle3Tablet}></div>
        </div>
      )}
      {isPhone && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle3Mobile}></div>
        </div>
      )}
      {!isPhone && !isTablet && !isLaptop && (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
        >
          <div style={styles.circle3}></div>
        </div>
      )}
      {!isPhone ? (
        <div
          style={{
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
            padding: isTablet ? "0px 18px 0px 18px" : "0px 60px 0px 95px",
            justifyContent: "space-around",
            marginTop: isTablet ? 35 : 140,
            alignItems: isTablet ? "center" : "",
          }}
        >
          {isTablet && (
            <>
              <p style={styles.sectionTitles}>On vous propose également...</p>
              <p style={styles.sectionSubTitle}>Ball’N Coaching</p>
            </>
          )}
          <div style={styles.columnSections}>
            <img
              src={coachingImage}
              style={{
                width: 500,
                height: 400,
                borderRadius: "20px",
                marginTop: isTablet ? 50 : 150,
              }}
              alt="logo"
            />
          </div>
          <div
            style={{
              ...styles.columnSections,
              width: isLaptop ? 500 : 700,
              marginTop: 100,
            }}
          >
            {!isTablet && (
              <>
                <p style={styles.sectionTitles}>On vous propose également...</p>
                <p style={styles.sectionSubTitle}>Ball’N Coaching</p>
              </>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: isPhone ? 55 : 42,
              }}
            >
              <p style={styles.bnCoachingNb}>01</p>
              <p style={{ ...styles.bnCoachingInfo, marginLeft: 35 }}>
                {
                  <text
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    Mise en relation:
                  </text>
                }{" "}
                On te met en relation avec un personal Trainer trié avec soin
                autour de toi, tu n'as plus qu'à t'entraîner
              </p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 64 }}
            >
              <p style={styles.bnCoachingNb}>02</p>
              <p style={{ ...styles.bnCoachingInfo, marginLeft: 28 }}>
                {
                  <text
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    Service personnalisés:
                  </text>
                }{" "}
                Bénéficie de services personnalisés, shooting photo, vidéo,
                personal branding…
              </p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 64 }}
            >
              <p style={styles.bnCoachingNb}>03</p>
              <p
                style={{
                  ...styles.bnCoachingInfo,
                  marginLeft: 28,
                  marginBottom: 40,
                }}
              >
                {
                  <text
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    Communauté:
                  </text>
                }{" "}
                Fais partie de la communauté Basketball Ball’N Connect qui
                avance et progresse ensemble
              </p>
            </div>
            <a href="https://ballncoaching.com/" style={styles.ytbBouton}>
              EN SAVOIR PLUS
            </a>
          </div>
        </div>
      ) : (
        <div style={styles.appSection}>
          {isPhone && (
            <>
              <p style={styles.sectionTitles}>On vous propose également...</p>
              <p style={styles.sectionSubTitle}>Ball’N Coaching</p>
            </>
          )}
          <div style={styles.columnSections}>
            <img
              src={coachingImage}
              style={{
                width: isPhone ? "100%" : 568,
                height: isPhone ? 289 : 420,
                borderRadius: "20px",
                marginTop: isPhone ? 100 : 150,
              }}
              alt="logo"
            />
          </div>
          <div
            style={{
              ...styles.columnSections,
              width: isPhone ? "" : 700,
              marginTop: isPhone ? "" : 100,
            }}
          >
            {!isPhone && (
              <>
                <p style={styles.sectionTitles}>On vous propose également...</p>
                <p style={styles.sectionSubTitle}>Ball’N Coaching</p>
              </>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: isPhone ? 55 : 42,
              }}
            >
              <p style={styles.bnCoachingNb}>01</p>
              <p style={{ ...styles.bnCoachingInfo, marginLeft: 35 }}>
                {
                  <text
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    Mise en relation:
                  </text>
                }{" "}
                On te met en relation avec un personal Trainer trié avec soin
                autour de toi, tu n'as plus qu'à t'entraîner
              </p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 64 }}
            >
              <p style={styles.bnCoachingNb}>02</p>
              <p style={{ ...styles.bnCoachingInfo, marginLeft: 28 }}>
                {
                  <text
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    Service personnalisés:
                  </text>
                }{" "}
                Bénéficie de services personnalisés, shooting photo, vidéo,
                personal branding…
              </p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 64 }}
            >
              <p style={styles.bnCoachingNb}>03</p>
              <p
                style={{
                  ...styles.bnCoachingInfo,
                  marginLeft: 28,
                  marginBottom: 40,
                }}
              >
                {
                  <text
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    Communauté:
                  </text>
                }{" "}
                Fais partie de la communauté Basketball Ball’N Connect qui
                avance et progresse ensemble
              </p>
            </div>
            <a href="https://ballncoaching.com/" style={styles.ytbBouton}>
              EN SAVOIR PLUS
            </a>
          </div>
        </div>
      )}
      {/* SECTION BLOG */}
      {!isPhone && !isTablet ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={styles.sectionTitles}>Notre blog</p>
          <p style={styles.sectionSubTitle}>Nos derniers articles</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 48,
              justifyContent: "space-around",
              paddingLeft: "25px",
              paddingRight: "25px",
            }}
          >
            {posts.map((post) => (
              <ArticleCard
                key={post.id}
                img={post.frontmatter.image}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                content={post.excerpt}
                author={post.frontmatter.author}
                link={post.frontmatter.slug}
              ></ArticleCard>
            ))}
          </div>
          <a href="/blog" style={styles.ytbBouton}>
            VISITER NOTRE BLOG
          </a>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: 18,
            paddingRight: 18,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              ...styles.sectionTitles,
              textAlign: isTablet ? "center" : "start",
            }}
          >
            Notre blog
          </p>
          <p
            style={{
              ...styles.sectionSubTitle,
              textAlign: isTablet ? "center" : "start",
              marginBottom: 48,
            }}
          >
            Nos derniers articles
          </p>

          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside={true}
            customDot={<CustomDot />}
          >
            {posts.map((post) => (
              <ArticleCard
                key={post.id}
                img={post.frontmatter.image}
                title={post.frontmatter.title}
                date={post.frontmatter.date}
                content={post.excerpt}
                author={post.frontmatter.author}
                link={post.frontmatter.slug}
              ></ArticleCard>
            ))}
          </Carousel>
          <a
            href="/blog"
            style={{
              ...styles.ytbBouton,
              marginTop: 550,
              alignSelf: "center",
              marginBottom: 150,
            }}
          >
            VISITER NOTRE BLOG
          </a>
        </div>
      )}
      {/* SECTION EQUIPE */}
      <div
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          display: "flex",
          flexDirection: "column",
          marginTop: 50,
        }}
      >
        <div>
          <p style={styles.sectionTitles}>Notre équipe</p>
          <p style={styles.sectionSubTitle}>Qui sommes-nous ?</p>
        </div>
        <Carousel
          responsive={responsiveTeam}
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          customDot={<CustomDot />}
        >
          <TeamCard
            name={"Gabdou"}
            poste={"Fondateur - CEO"}
            img={gabdou}
            linkedin={
              "https://www.linkedin.com/in/aurelien-gabdou-baroa-b7a65930/"
            }
          ></TeamCard>
          <TeamCard
            name={"Anoureth"}
            poste={"Co-Fondateur - CTO"}
            img={anoureth}
            linkedin={"https://www.linkedin.com/in/anoureth-pongrattana/"}
          ></TeamCard>
          {/* <TeamCard
            name={"Chloé"}
            poste={"Responsable Marketing"}
            img={chloe}
            linkedin={"https://www.linkedin.com/in/chlo%C3%A9-zami-7375aa177/"}
          ></TeamCard>
          <TeamCard
            name={"Tiago"}
            poste={"Développeur Web/Mobile"}
            img={tiago}
            linkedin={"https://www.linkedin.com/in/tiago-de-sa-13123317a/"}
          ></TeamCard>
          <TeamCard
            name={"Tristan"}
            poste={"Développeur Web/Mobile"}
            img={tristan}
            linkedin={"https://www.linkedin.com/in/tristan-gualini/"}
          ></TeamCard> */}
        </Carousel>
      </div>
      <div id="faq"></div>
      {/* SECTION FAQ */}
      <div
        style={{
          paddingLeft: 18,
          paddingRight: 18,
          display: "flex",
          flexDirection: "column",
          marginTop: 50,
        }}
      >
        <div>
          <p style={styles.sectionTitles}>FAQ</p>
          <p style={styles.sectionSubTitle}>Questions / Réponses</p>
          <div
            style={{
              display: "flex",
              flexDirection: !isPhone && !isTablet ? "row" : "column",
            }}
          >
            <div
              style={{
                marginTop: isLaptop ? 0 : 20,
                marginBottom: 15,
                marginRight: !isPhone && !isTablet ? 10 : 0,
              }}
            >
              <p style={{ marginBottom: 15, ...styles.sectionTitles }}>
                Général :
              </p>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    L’application Ball’N Connect c’est quoi ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Notre application est une plateforme sociale pour les
                    joueurs de basket (regroupant plus de 40 000 utilisateurs).
                    <br /> Elle permet de connecter la communauté fan de basket
                    autour de 3 grands axes : <br />● Find : Géolocalise les
                    terrains autour de toi et les utilisateurs de l’appli pour
                    savoir sur quel playground vous réunir. <br />● Play :
                    Organise tes matchs et tournois directement via notre
                    application. Tu peux aussi réserver des terrains dans
                    certaines salles payantes.
                    <br /> ● Progress : Mise en relation directe avec des coachs
                    de basket et plus tu viendras sur nos playgrounds, plus tu
                    gagneras en skills !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Dois-je être licencié dans un club de basketball ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Non, aucune obligation de licence. Notre application
                    souhaite développer et faciliter la pratique du basket en
                    l’ouvrant à tout le monde.
                    <br />
                    Alors rejoins notre communauté !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Où puis-je me procurer l’application Ball’N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    L’application est disponible dans le Play Store ainsi que
                    l’App Store.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    L’application est payante ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    L’application bénéficie d’une interface gratuite. Une
                    expérience plus complète viendra bientôt compléter cette
                    dernière via un service Premium.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    À qui s’adresse l’application ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Bien sûr ! Ball’N Connect permet à toute la communauté
                    basket, sans distinctions, de se retrouver.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment créer mon compte ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Télécharge l’application sur ton Store puis crées-toi un
                    profil grâce à un compte Facebook, Apple ou via Mail. <br />
                    Tu pourras ensuite compléter toutes les informations sur ton
                    profil et te défier aux joueurs proches de toi !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Quels niveaux de jeu sont disponibles sur l’application ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Du débutant au All-Star, Ball’N Connect ne laisse personne
                    sur le banc de touche. Prends ta meilleure paire et
                    retrouve-nous sur les playgrounds.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {!isPhone && !isTablet && (
                <>
                  <p
                    style={{
                      ...styles.sectionTitles,
                      marginTop: 20,
                      marginBottom: 15,
                    }}
                  >
                    Collaboration :
                  </p>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Comment mon entreprise peut-elle soutenir Ball’N Connect
                        ?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        L’équipe de Ball’N Connect est à l’écoute de toutes les
                        propositions que vous pouvez faire.
                        <br />
                        Ce serait un grand plaisir de collaborer pour promouvoir
                        la communauté basket et rendre le jeu plus accessible !
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: isLaptop ? 0 : 20,
                marginBottom: 10,
                marginLeft: !isPhone && !isTablet ? 10 : 0,
              }}
            >
              <p style={{ marginBottom: 15, ...styles.sectionTitles }}>
                Communauté / Utilisateur :
              </p>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment trouver des playgrounds près de chez moi ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Il te suffit d’ouvrir l’application et d’autoriser l’accès à
                    ta localisation. <br /> Après cela, tu pourras naviguer sur
                    la map et trouver tous nos playgrounds !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Où sont référencés les terrains ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ball’N Connect espère pouvoir te permettre de trouver un
                    terrain partout, n’importe où, où que tu sois.
                    <br />
                    Nous comptons aujourd’hui plus de 4000 terrains à travers le
                    monde entier.
                    <br />
                    Alors si le playground près de chez toi n’existe pas encore,
                    ajoute-le !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment ajouter son terrain sur l’application Ball’N Connect
                    ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Pour ajouter un terrain, rends-toi dans le “+” en bas de
                    l’application puis “Ajouter terrain”. <br /> On te demandera
                    ensuite les spécificités du terrain, des paniers et du sol.
                    <br />
                    Ajoute ensuite des photos du terrain et le tour et jouer, il
                    sera visible par toute la communauté.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Quels types de terrains peuvent être ajoutés sur
                    l’application Ball’N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Oui, tous les terrains peuvent être intégrés à notre
                    application sans conditions particulières ! <br />
                    Notre équipe de modération valide ensuite le nouveau
                    playground et il se retrouve dans l’application.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment créer un match depuis l’application Ball’N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Pour créer un match, rends-toi dans le “+” en bas de
                    l’application puis “Ajouter match”. <br />
                    Renseigne le lieu, la date et l’heure du match. Pense aussi
                    à renseigner les spécificités sur le format du match,
                    l’ambiance ou encore le niveau de jeu.
                    <br /> Maintenant, plus qu’à inviter la communauté à te
                    rejoindre !
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment rejoindre un match disponible sur l’application
                    Ball’N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Tu peux accéder à tous les matchs disponibles à partir de
                    l’accueil dans notre application. <br />
                    Prend connaissance des spécificités du match puis tu n’as
                    plus qu’à cliquer sur “Participer” pour prendre part au
                    match.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment rentrer en contacter avec les membres inscrits à un
                    match ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Si vous vous êtes inscrit à un match mais n’êtes plus
                    disponible, pas de soucis ! <br /> Vous pouvez à tout moment
                    retirer votre participation via l’application.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          {(isTablet || isPhone) && (
            <div
              style={{
                marginTop: isLaptop ? 0 : 20,
                marginBottom: 15,
              }}
            >
              <p style={{ marginBottom: 15, ...styles.sectionTitles }}>
                Collaboration :
              </p>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Comment mon entreprise peut-elle soutenir Ball’N Connect ?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    L’équipe de Ball’N Connect est à l’écoute de toutes les
                    propositions que vous pouvez faire.
                    <br />
                    Ce serait un grand plaisir de collaborer pour promouvoir la
                    communauté basket et rendre le jeu plus accessible !
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </div>
      {/* <MapPlayground></MapPlayground> */}
      {/* FOOTER*/}
      <Footer id="contact"></Footer>
      {/* {isPhone
        ? modalAds && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                top: 0,
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  width: 320,
                  height: 480,
                  backgroundImage: "blue",
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "sticky",
                  top: 100,
                }}
              >
                <a
                  onClick={() => {
                    setModalAds(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    position: "absolute",
                    right: 0,
                  }}
                >
                  Fermer X
                </a>
                <a
                  target="blank"
                  href="http://onelink.to/fsbntf"
                  onClick={() =>
                    window.gtag("event", "clickPumaAdMobile", {
                      name: "blogHome",
                    })
                  }
                >
                  <img
                    src={bdpLille}
                    alt="Boss du Playground Ads"
                    style={{ width: 320, height: "auto" }}
                  />
                </a>
              </div>
            </div>
          )
        : modalAds && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                top: 0,
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  width: 320,
                  height: 480,
                  backgroundImage: "blue",
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "sticky",
                  top: 100,
                }}
              >
                <a
                  onClick={() => {
                    setModalAds(false);
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    position: "absolute",
                    right: 0,
                  }}
                >
                  Fermer X
                </a>
                <a
                  target="blank"
                  href="http://onelink.to/fsbntf"
                  onClick={() =>
                    window.gtag("event", "clickPumaAd", { name: "blogHome" })
                  }
                >
                  <img
                    src={bdpLille}
                    alt="Boss du Playground Ads"
                    style={{ width: 320, height: "auto" }}
                  />
                </a>
              </div>
            </div>
          )} */}
    </div>
  );
}

export const pageQuery = graphql`
  query HomeQuery {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true, locale: "FR")
          title
          author
          slug
          image
        }
        excerpt(pruneLength: 400)
        id
      }
    }
  }
`;
